<template>
  <div class="mymap" id="container"></div>
</template>

<script>
import AMap from 'AMap'
export default {
  data() {
    return {
      map: {},
      marker_position: [],
      marker_label:[],
      editorColor: {
        1: {
          strokeColor: '#059370',
          fillColor: '#9ed09d'
        },
        2: {
          strokeColor: '#EA546D',
          fillColor: '#de7171'
        },
        3: {
          strokeColor: '#71076e',
          fillColor: '#b374b1'
        }
      },
      title: '',
      // cluster: null,
      // labelCluster: null,
    }
  },

  methods: {
    loadmap() {
      this.map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        zoom: 13,
        // resizeEnable: true,
      })
    },
    viewMask(row){
      let rowdata = {...row}
      rowdata.timeArr = [rowdata.forwardTime.substring(11,19),rowdata.gpsTime.substring(11,19),rowdata.behindTime.substring(11,19)]
      this.clearMap();
      var points=[{position: rowdata.forward},{position: rowdata.middle},{position: rowdata.behind}];
      for (let i = 0; i < points.length; i++) { 
          let marker = new AMap.Marker({
            map: this.map,
            zIndex: 999,
            position: [points[i].position[0], points[i].position[1]],
            offset: new AMap.Pixel(-13, -30)
          });
          this.marker_position.push(marker)
          let content = `<div>
                        <p style="color:#059370">${rowdata.timeArr[i]},${rowdata.arr[i]}</p>
                      </div>`
          let text = new AMap.Text({
            map: this.map,
            position: [points[i].position[0], points[i].position[1]],
            text: content,
            offset: new AMap.Pixel(0, -52),
            style: {
              'font-size': '14px',
              border: 'none',
              padding: '6px 10px',
              'background-color': '#f6f6f9',
              'box-shadow': '0px 2px 8px 0px rgba(200, 201, 204, 0.5)',
              'line-height': '20px'
            }
          })
          this.marker_label.push(text)
          marker.setMap(this.map);
      }
      //画轨迹线
      var path = [
          new AMap.LngLat(rowdata.forward[0],rowdata.forward[1]),
          new AMap.LngLat(rowdata.middle[0],rowdata.middle[1]),
          new AMap.LngLat(rowdata.behind[0],rowdata.behind[1])
      ];
        var polyline = new AMap.Polyline({
          map: this.map,
          path: path, // 这里是轨迹的坐标拼成的数组
          showDir: true,
          strokeColor: "#28F", //线颜色
          // strokeOpacity: 1,   //线透明度
          strokeWeight: 6, //线宽
          strokeStyle: "round" //线样式
          });
      this.map.setFitView();//自动调整到合适的位置
    },
    addCluster () {
      let self = this
      if (self.cluster) {
        self.cluster.setMap(null)
        self.labelCluster.setMap(null)
      }
      self.cluster = new AMap.MarkerClusterer(self.map, self.marker_position)
      self.labelCluster = new AMap.MarkerClusterer(self.map, self.marker_label)
    },
    clearMap() {
      this.map.clearMap()
      // this.cluster = null
      // this.labelCluster = null
     this.marker_position = [];
      this.marker_label = [];
      this.map.remove(this.marker_label);
      this.map.remove(this.marker_position);
    }
  },
  mounted() {
    this.loadmap() // 加载地图和相关组件;
  }
}
</script>

<style>
.mymap {
  width: 100%;
  height: 63vh;
}
</style>
