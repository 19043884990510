import { render, staticRenderFns } from "./driftDetail.vue?vue&type=template&id=3bfa3cdd&scoped=true&"
import script from "./driftDetail.vue?vue&type=script&lang=js&"
export * from "./driftDetail.vue?vue&type=script&lang=js&"
import style0 from "./driftDetail.vue?vue&type=style&index=0&id=3bfa3cdd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bfa3cdd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-45165106-158994/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bfa3cdd')) {
      api.createRecord('3bfa3cdd', component.options)
    } else {
      api.reload('3bfa3cdd', component.options)
    }
    module.hot.accept("./driftDetail.vue?vue&type=template&id=3bfa3cdd&scoped=true&", function () {
      api.rerender('3bfa3cdd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/projectGps/drivingStatistics/driftStatistics/components/driftDetail.vue"
export default component.exports