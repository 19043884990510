<template>
  <div class="equipment-statistics" ref="equipmentStatistics">
    <div class="form-area" ref="form">
      <analysisForm :showMultDay="true" @getFormVal="onSearch">        
        <el-button
          type="primary"
          size="small"
          :disabled="tableData[0].map.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </analysisForm>
      <el-form
        ref="explainData"
        :model="explainData"
        class="demo-form-inline"
        label-position="left"
        label-width="92px"
      >
        <el-form-item
          label="漂移次数:"
        >
        {{explainData.total?explainData.total:'0'}}次
        </el-form-item>
        <el-form-item
          label="上线车辆数量:"
        >
        {{explainData.online?explainData.online:'0'}}辆  
        </el-form-item>
        <el-form-item
          label="平均漂移次数:"
        >
        {{explainData.avg?explainData.avg:'0'}}次/辆
        </el-form-item>
        <el-form-item
          label="按照文件说明:"
        >
          <div>漂移次数大于平均次数的才算漂移车辆</div>
        </el-form-item>
      </el-form>
    </div>
    <el-table
    ref="tableName"
      :data="tableData"
      stripe
      :height="tableHeight"
      style="width: 100%"
    >
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="200px"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="110 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="licensePlateColorStr"
        label="车牌颜色"
      >
      </el-table-column>
      <el-table-column prop="deviceNo" label="终端号" :width="160 * $store.state.vehicle.screenCoefficient"></el-table-column>
      <el-table-column prop="total" label="漂移总次数" :width="120 * $store.state.vehicle.screenCoefficient">
        <template slot-scope="scope">
          <div @click="openDailog(scope.row.total,scope.row.vehicleNo,form.beginTime,form.endTime)" class="table-row">{{scope.row.total}}</div>
        </template>
      </el-table-column>
       <el-table-column
        v-for="(obj, key, index) in tableData[0].map"
        :key="index"
        width="120px"
        :label="queryDate(key)"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div class="table-row" @click="openDailog(tableData[scope.$index].mapArr[index].label,scope.row.vehicleNo,tableData[scope.$index].mapArr[index].time,tableData[scope.$index].mapArr[index].time)">{{tableData[scope.$index].mapArr[index].label}}
          </div>
        </template>
       </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 具体日期弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      width="80%"
      :visible.sync="dialogTableVisible"
      v-if="dialogTableVisible"
    >
     <drift-detail :dialogFrom="dialogFrom"></drift-detail>
    </el-dialog>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import { queryDriftForm } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import driftDetail from './components/driftDetail'
export default {
  name: "driftStatistics",
  components: {
    analysisForm,
    'drift-detail':driftDetail
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [
        {map:[]}        
      ],
      explainData:{},
      form: {
        currentPage: 1,
        pageSize: 10,
        beginTime:'',
        endTime:''
      },
      typeObj: {},
      alarmTypeObj: {},
      colorObj: {},
      total: 0,
      exportForm: {},
      downLoadStatus: false,
      title:'漂移详情',
      dialogTableVisible:false,
      dialogFrom:{},
    };
  },
  computed:{
    queryDate(){
      return function(obj){
        return obj.substring(5,10)
      }
    }
  },
  methods: {    
    //打开弹窗
    openDailog(num,vehicleNo,beginTime,endTime){
      if(num===0)return
      const data = {
        vehicleNos:[vehicleNo],
        beginTime,
        endTime,
        currentPage: 1,
        pageSize: 10,
      }
      this.dialogFrom = data;
      this.dialogTableVisible = true;
    },
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.equipmentStatistics.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =wholeHeight - 32 - formHeight - paginationHeight - 10;
    },
    //导出
    exportData() {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        fileName: `漂移点导出-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/drift/exportDriftPoint",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 点击查询
    onSearch(value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    ObjsubString(obj){
      return obj.substring(5,10)
    },
    //获取数据
    getListByField() {
      let data = { ...this.form };
      queryDriftForm(data).then((res) => {
        // console.log('漂移数据',res)
        if (res.code === 1000) {
          if(res.data.pageList){
            res.data.pageList.list.map(item=>{
              item.mapArr=[];
              let obj = item.map
              for(let key in obj){
                item.mapArr.push({label:obj[key],time:key})
                // item.mapArr.push(obj[key]);
              }
            })
            this.tableData = res.data.pageList.list;
            this.$nextTick(() => {
              this.$refs.tableName.doLayout();
            })
            this.total = res.data.pageList.total;
            this.explainData = res.data.driftPointTotal;
          }else{
            this.tableData =[{map:[]}];
            this.total = 0;
            this.explainData = {
              total:0,
              online:0,
              avg:0
            };
          }
          // if (res.data.pageList.list.length === 0 && data.currentPage !== 1) {
          //   this.form.currentPage -= 1;
          //   this.getListByField();
          // }
        } else {
          this.tableData = [{map:[]} ];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getListByField();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.getListByField();
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,D0010" }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY);
          this.colorObj = formatDict(res.data.CPYS);
          this.alarmTypeObj = formatDict(res.data.D0010);
        }
      });
    },
  },
  created() {
    this.getDicts();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.equipment-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}
.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .equipment-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.table-row{
  cursor: pointer;
}
.demo-form-inline{
  grid-template-columns: 15% 15% 15% 50%;
  gap: 5px 10px;
}
</style>
